<template>
  <div class="swiper-container message">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in list" :key="item._id" >
        <div class="message-box">
          <div v-html="item.message" class="message-input"></div>
          <h5>{{ item.name }}</h5>
        </div>
      </div>
    </div>

    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper, { EffectFade, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
  props: [
    'list'
  ],
  mounted(){
    Swiper.use([EffectFade, Navigation]);
    
    var mySwiper = new Swiper('.swiper-container', {
      spaceBetween: 30,
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }
}
</script>

<style lang="scss">
.swiper-container{
  width: 70%;
  max-width: 600px;
  position: relative;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%) translateY(0);
  @apply absolute flex overflow-visible;

  &::before{
    content: '';
    position: absolute;
    bottom: 1px;
    left: 47%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
    z-index: 999;
  }
}

.swiper-wrapper{
  @apply relative;
}

.swiper-button-prev, .swiper-button-next{
  color: #fff;
  bottom: -33%;
  top: auto;
  background-color: #25a9e0;
  
  @apply flex justify-center items-center rounded-full w-8 h-8 shadow-lg;
  &::after{
    font-size: 100%;

    @apply text-white font-bold leading-none;
  }
}

.swiper-button-prev{
  left: 0rem;
  &::after{
    padding-right: 3px;
  }
}
.swiper-button-next{
  right: 0rem;
  &::after{
    padding-left: 3px;
  }
}

.swiper-slide{
  @apply flex flex-col items-end justify-center w-full mt-auto;
  
  .message-box{
    @apply flex flex-col px-6 pt-4 pb-6 w-full bg-white rounded-lg shadow-xl;
  }

  .message-input{
    line-height: 1.75!important;
    @apply text-xs tracking-wider text-left text-gray-700;
  }

  h5{
    @apply font-semibold mt-4 text-xs tracking-wider text-left text-blue-300 pl-5 relative;

    &::before{
      content: "";
      height: 1px;
      top: 50%;
      @apply inline-block bg-blue-300 w-3 mr-1 absolute left-0;
    }
  }

}
</style>