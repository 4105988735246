import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    appLoaded: false,
    itemLoaded: 0,
    total: 5,
    sky: 'day',
    audio: false,
  },
  mutations: {
    loaded (state) {
      state.appLoaded = true
    },
    toggleSky(state, day) {
      state.sky = day
    },
    onAudio(state){
      state.audio = true
    },
    offAudio(state){
      state.audio = false
    },
  },
  actions: {
    loaded ({commit, state}) {
      state.itemLoaded++
      
      if(state.itemLoaded >= state.total){
        commit('loaded')
      }
    }
  }
})