import Vue from 'vue'
import App from './App.vue'
import browserDetect from "vue-browser-detect-plugin";
import { store } from './store/index.js'
import './assets/styles/index.css'
import './assets/styles/index.scss'

Vue.use(browserDetect);

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
