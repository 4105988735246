<template>
  <div id="app">
    <LoadScreen />
    <Nav />
    <QRcode />
    <!-- <ScapeIE v-if="agent" /> -->
    <Scape :id="ieCheck" />
    <!-- <Sky /> -->
  </div>
</template>

<script>
import LoadScreen from '@/components/LoadScreen'
import Nav from '@/components/Nav.vue'
import Scape from '@/components/city/Scape.vue'
// // import Sky from '@/components/Sky'


export default {
  name: 'App',
  components: {
    LoadScreen,
    Nav,
    Scape,
    QRcode: () => import('@/components/QRcode.vue')
    // Sky,
    // ScapeIE: () => import('@/components/city/ScapeIE.vue'),
  },
  computed: {
    ieCheck(){
      return this.$browserDetect.isIE ? 'ie-browser' : '';
    }
  }
}
</script>

<style lang="scss">
*{
  user-select: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  background: url('/images/dec/bg.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;


  @apply block w-full bg-blue-300;
}

</style>
