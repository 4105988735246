<template>
  <section id="scene-wrapper" class="flex flex-col justify-center items-center z-10 relative" @mousemove="">
    <!-- hint -->
    <!-- <div class="fixed z-50 top-0 left-0 bg-gray-800 text-white text-xs p-2">{{screenWidth}}</div> -->


    <!-- <Snow /> -->
    <!-- <Audio /> -->
    <div id="scene" ref="scene" data-pointer-events="true">
      <div data-depth-x="0.2" data-depth-y="0" id="bg-scene-layer">
        <div>
          <div class="image-wrapper">
            <img src="/images/dec/buildings_shadow.png" @load="itemLoaded">
          </div>
        </div>
      </div>
      <div data-depth-x="0.6"  data-depth-y="0" id="fore-scene-layer">
        <div class="">
          <div id="bg-hover" v-if="!mobileMessage">
            <ul class="message-dots">
              <li v-for="(item, index) in list" :style="{'top': item.pos[0] +'%', 'left': item.pos[1]+'%' }" :class="activeMessage(index)">
                <span class="hover-dot" @mouseover="hoverIn(index)"></span>
                <div class="ring-effect"></div>
              </li>
            </ul>
          </div>

          <div class="image-wrapper">
            <img src="/images/dec/buildings_fore.png" @load="itemLoaded">
          </div>
        </div>
      </div>
      
      <div id="tree-layer" data-depth-x="0.75" data-depth-y="0">
        <div>
          <span>
            <img src="/images/dec/tree1.png" @load="itemLoaded">
          </span>
          <span>
            <img src="/images/dec/tree2.png" @load="itemLoaded">
          </span>
        </div>
      </div>
      <div id="title-layer" data-depth-x="0.4" data-depth-y="0">
        <div>
          <div class="pb-10">
            <h1>Thank You For <span class="inline-block">Standing Strong Together</span></h1>
            <span class="logo">
              <img src="/images/dec/logo.png" class="logo h-full" @load="itemLoaded">
            </span>
          </div>
        </div>
      </div>

      <div id="santa-layer" data-depth-x="0.9" data-depth-y="0">
        <div>
          <!-- title (start) -->
          
          <!-- title (end) -->
          <!-- message(start) -->
          <div id="message-layer">
            <SwiperMessage :list="list" v-if="mobileMessage" />
           
              <transition-group name="list" tag="div" class="message-wrapper" v-if="!mobileMessage">
                <span v-for="(item, index) in list" :key="item._id" v-if="index == selected">
                  <div class="btn-close" @click="close">
                    <svg viewBox="0 0 365.696 365.696" xmlns="http://www.w3.org/2000/svg"><path d="M243.188 182.86L356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"/></svg>
                  </div>
                  <div v-html="item.message" class="message-input"></div>
                  <h5>{{ item.name }}</h5>
                </span>
              </transition-group>
           
          </div>
          <!-- message(end) -->
          <div class="santa-wrapper">
            <figure>
              <img src="/images/dec/santa.png" class="santa" @load="itemLoaded">
            </figure>
          </div>
        </div>
      </div>
      
    </div>



  </section>
</template>

<script>
// import Snow from '@/components/home/Snow'
import SwiperMessage from '@/components/home/SwiperMessage'
// // import Audio from '@/components/Audio'

import { messages } from '@/assets/js/messages'
import Parallax from 'parallax-js'


export default {
  name: 'Scape',
  components: {
    // Snow,
    SwiperMessage
  },
  data(){
    return {
      selected: null,
      list: messages,
      parallaxInstance: null,
      mobileWidth: 800,
      mobileMessage: false,
      screenWidth: 0,
    }
  },
  methods: {
    hoverIn(index){
      this.selected = index
    },
    close(){
      this.selected = null
    },
    activeMessage(index){
      return this.selected === index ? "active" : ""
    },
    itemLoaded(){
      // console.log('loaded')
      this.$store.dispatch('loaded')
    },

    sceneInit(){
      this.parallaxInstance = new Parallax(this.$refs.scene, {
        onReady: function () {
          
        }
      });
    },
    handlerResize(){
      this.screenWidth = window.innerWidth

      this.sceneInit()

      if(window.innerWidth >= this.mobileWidth){
        this.mobileMessage = false
      }else{
        this.mobileMessage = true
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handlerResize)
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handlerResize);

      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    })

    this.handlerResize()

  }
}
</script>

<style lang="scss">



</style>